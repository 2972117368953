import React from 'react'
import AnimatedLink from '../components/AnimatedLink'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import Paragraph from '../components/Paragraph'
import Spacer from '../components/Spacer'

export default function success() {
    return (
        <Layout>
            <PageHeader>Thank you!</PageHeader>
            <Paragraph top>Your message has been received, and I will reach out to you soon!</Paragraph>
            <Spacer size={30} />
            <AnimatedLink to="/">RETURN HOME</AnimatedLink>
        </Layout>
    )
}
